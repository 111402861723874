import React from 'react';

const MailToElement = ({mailtoData, text}) => {
    if (!!mailtoData) {
        let hrefLink = `mailto:${mailtoData.mail}?`;
        hrefLink += !!mailtoData.cc && mailtoData.cc.trim() !== "" ? `cc=${mailtoData.cc.replace(' ', '%20')}&amp;` : '';
        hrefLink += !!mailtoData.bcc && mailtoData.bcc.trim() !== "" ? `bcc=${mailtoData.bcc.replace(' ', '%20')}&amp;` : '';
        hrefLink += !!mailtoData.subject && mailtoData.subject.trim() !== "" ? `subject=${mailtoData.subject.replace(' ', '%20')}&amp;` : '';
        hrefLink += !!mailtoData.content && mailtoData.content.trim() !== "" ? `body=${mailtoData.content.replace(' ', '%20')}&amp;` : '';

        return <a className={'mailto'} href={hrefLink}>{!!text ? text : 'Contact'}</a>
    } else {
        return <a className={'mailto'} href="mailto:">{!!text ? text : 'Contact'}</a>
    }
};

export default MailToElement;