import React, {useEffect, useState, useRef} from 'react';
import ImageGallery from "react-image-gallery";
import {animateScroll as scroll} from 'react-scroll'
import {Element} from "react-scroll/modules";

const baseSize = 64;

export default function Gallery ({render}) {
    const [images, setImages] = useState([]);
    const [size, setSize] = useState(baseSize);
    const [isFullScreen, setFullscreen] = useState(null);
    const GalleryRef = useRef(null);

    useEffect(() => {
        if(images.length <= 0){
            scroll.scrollTo('gallery');
            fetchData('/config/gallery.json').then((res) => {
                setImages(res);
                render();
            });
        }
    }, [images, render]);

    const fetchData = async filePath => {
        try {
            const res = await fetch(filePath);
            return await res.json();
        } catch (err) {
            return err;
        }
    };

    const openImg = (i) => () => {
        GalleryRef.current.slideToIndex(i);
        GalleryRef.current.fullScreen();
    };

    const addSize = () => {
      setSize(size + baseSize);
      render();
    };

    return <div className={'container-height gallery'}>
        <Element name={'gallery'} className={'img-gallery'}>
            {images.length > 0 && images.slice(0,size).map((element, i) => (
                <div key={i} className={'img-gallery-wrapper'} onClick={openImg(i)} style={{cursor: 'pointer'}}>
                    <img  src={element.thumbnail} className={'img-of-gallery'} alt={'gallery'}/>
                </div>
            ))}
            <div style={{height: '0', width: '0'}}>
                {images.length > 0 && <ImageGallery
                    ref={GalleryRef}
                    items={images}
                    showNav={!!isFullScreen}
                    showThumbnails={!!isFullScreen}
                    showFullscreenButton={!!isFullScreen}
                    showIndex={false}
                    showPlayButton={!!isFullScreen}
                    showBullets={false}
                    slideDuration={!!isFullScreen ? 450 : 0}
                    onScreenChange={setFullscreen}/> }
            </div>
        </Element>
        {images.length > size && <div className={'render-more'} onClick={addSize}>
            <span> Click to show more </span>
        </div>}
    </div>

};