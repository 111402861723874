import React, {useState, useEffect} from 'react'
import GeoLocation from "../Map";
import contactIcon from "../../res/contact-icon.svg";
import {MailToElement} from "../index";
import {Element} from "react-scroll";

function ContactContainer({data, mailTo}) {
    const [timeoutBool, setTimeoutBool] = useState(false);
    const formatText = text => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    };

    useEffect(() => {
        if (!!data && !!data.header) {
            setTimeout(() => setTimeoutBool(true), 2000)
        }
    }, [data]);

    return <Element name={'contact'} className={'container contact'}>
        <div className={'header-line'}>
            <h2>{data.header}</h2>
        </div>
        <div className={'contact-wrapper'}>
            <div className={'map-wrapper'}>
                {timeoutBool && <GeoLocation/>}
            </div>
            <div className={'info-wrapper'}>
                <div className={'img-wrapper'}><img src={contactIcon} alt={'Img from Icon'}/></div>
                <div className={'displayed-info'}>
                    {!!data.contact && Object.keys(data.contact).map((keys, i) => (
                        !!data.mailto && data.mailto.trim() !== '' && data.mailto.trim() === keys ?
                            <div key={i}><span className={'label'}>{formatText(keys)}:</span> <MailToElement mailtoData={mailTo} text={data.contact[keys]}/>
                            </div> :
                            <div key={i}><span className={'label'}>{formatText(keys)}:</span> <span>{data.contact[keys]}</span></div>
                    ))}
                </div>
            </div>
        </div>
    </Element>
}

export default ContactContainer