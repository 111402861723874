import React, {useState} from 'react';
import logoA from "../../res/vreec-logo-19-a.png";
import {Link, scroller} from "react-scroll";
import {FontAwesomeIcon as Icon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {
    Link as RouterLink,
    useHistory,
    useLocation,
    matchPath
} from "react-router-dom";

function TitleBar ({data, headerVisible}) {
    const history = useHistory();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState('close');
    const titleBarHidden = !headerVisible ? 'navbar--hidden' : '';

    const toMainPage = () => {
        history.push('/');
    };

    const toggleMenu = e => {
        isOpen === 'open' ? setIsOpen('close'): setIsOpen('open');
    };

    const smallLinkTo = target => e =>{
        setIsOpen('close');
        scroller.scrollTo(target, {
            duration: 1500,
            smooth: true,
        });
    };

    return <div className={`header ${titleBarHidden}`}>
        <div className={'black header-content'} onClick={toMainPage}>
            <div className={'sm-hidden header-logo-wrapper'}>
                <img src={logoA} alt={'Vreeclimber Logo'} className={'header-logo'}/>
            </div>
            <h1 className={'header-title'}>VREECLIMBER</h1>
        </div>
        <div className={'yellow header-content'}>
            {matchPath(location.pathname, '/').isExact ?
                <ul className={'link-list'}>
                    {data.about && <li className={'sm-hidden '}><Link to={'about'} smooth={true} duration={1000}>About</Link></li>}
                    {data.technologies && <li className={'sm-hidden '}><Link to={'technologies'} smooth={true} duration={1000}>Technologies</Link></li>}
                    {data.setup && <li className={'sm-hidden '}><Link to={'setup'} smooth={true} duration={1000}>Setup</Link></li>}
                    {data.sponsors && <li className={'sm-hidden '}><Link to={'sponsors'} smooth={true} duration={1000}>Sponsors</Link></li>}
                    {data.events && <li className={'sm-hidden '}><Link to={'events'} smooth={true} duration={1000}>Events</Link></li>}
                    {data.contributor && <li className={'sm-hidden '}><Link to={'contributors'} smooth={true} duration={1000}>Contributors</Link></li>}
                    {data.contact && <li className={'sm-hidden '}><Link to={'contact'} smooth={true} duration={1000}>Contact</Link></li>}
                    {<li className={'sm-show'} onClick={toggleMenu}><Icon icon={faBars}/></li>}
                </ul> :
                <ul className={'link-list'}>
                    <li><RouterLink to={'/'}>Back</RouterLink></li>
                </ul>}
        </div>
        <div className={`small-menu ${isOpen}`}>
            <ul className={'link-list'}>
                {data.contact && <li onClick={smallLinkTo('contact')}>Contact</li>}
                {data.about && <li  onClick={smallLinkTo('about')}>About</li>}
                {data.technologies && <li onClick={smallLinkTo('technologies')}>Technologies</li>}
                {data.setup && <li onClick={smallLinkTo('setup')}>Setup</li>}
                {data.sponsors && <li onClick={smallLinkTo('sponsors')}>Sponsors</li>}
                {data.events && <li onClick={smallLinkTo('events')}>Events</li>}
                {data.contributor && <li onClick={smallLinkTo('contributors')}>Contributors</li>}
            </ul>
        </div>

    </div>
}

export default TitleBar;