import React, {useEffect, useState} from 'react';
import {animateScroll as scroll} from 'react-scroll'
import {Element} from "react-scroll/modules";
import {ProjectElement} from "../../components";

export default function Projects ({render}) {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        if(projects.length <= 0){
            scroll.scrollTo('projects');
            fetchData('/config/projects.json').then((res) => {
                setProjects(res);
                render();
            });
        }
    }, [projects.length,render]);

    const fetchData = async filePath => {
        try {
            const res = await fetch(filePath);
            return await res.json();
        } catch (err) {
            return err;
        }
    };

    return <div className={'container-height gallery'}>
        <Element name={'projects'} className={'project-gallery'}>
            { !!projects && projects.length > 0 && projects.map( (project,i) => (
                <ProjectElement key={i} project={project} />
            )) }
        </Element>
    </div>

};