import React, {useEffect, useState, useRef} from 'react';
import {
    Switch,
    Route,
} from "react-router-dom";

import './App.css';
import Main from './pages';
import Legal from './pages/legal';
import "react-image-gallery/styles/css/image-gallery.css";
import Gallery from "./pages/gallery";
import Projects from "./pages/projects";
import {FooterBar,TitleBar} from "./components";

function debounce (fn, ms) {
    let timer;
    return _ => {
        clearTimeout(timer);
        timer = setTimeout(_ => {
            timer = null;
            fn.apply(this, arguments)
        }, ms)
    };
}

function App() {
    const _containerHeight = useRef();
    const [data, setData] = useState(null);

    const [prevScrollPos, _setPrevScrollPos] = useState(window.pageYOffset);
    const [headerVisible, _setHeaderVisible] = useState(true);
    const [backgroundCount, setBackgroundCount] = useState(10);
    const [hiddenFooter, setHiddenFooter] = useState(true);
    const prevScrollPosRef = useRef(prevScrollPos);
    const headerVisibleRef = useRef(headerVisible);


    const setPrevScrollPos = data => {
        prevScrollPosRef.current = data;
        _setPrevScrollPos(data);
    };
    const setHeaderVisible = data => {
        headerVisibleRef.current = data;
        _setHeaderVisible(data);
    };

    const handleScroll = () => {

        const currentScrollPos = window.pageYOffset;
        const visible = prevScrollPosRef.current > currentScrollPos;

        setPrevScrollPos(currentScrollPos);
        setHeaderVisible(visible);
    };

    useEffect(() => {
        if(!data){
            fetchData('/config/config.json').then((res) => {
                setData(res);
            });
        }

        const debouncedHandleResize = debounce(renderBackground, 100);

        window.addEventListener("scroll", handleScroll);
        window.addEventListener('resize', debouncedHandleResize);

        return _ => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", debouncedHandleResize);
        }
    }); // mount


    const fetchData = async filePath => {
        try {
            const res = await fetch(filePath);
            return await res.json();
        } catch (err) {
            return err;
        }
    };



    const renderBackground = () => {
        setHiddenFooter(true);
        setTimeout(() => {
            const count = _containerHeight.current.clientHeight / window.innerHeight;
            setBackgroundCount(Math.floor(count));
            setHiddenFooter(false);
        }, 500);
    };


    const backgroundRenderNumber = i => {
        if (backgroundCount >= 9) {
            return i < 10 ? Math.ceil(i / 2) : 5;
        } else {
            return i < 5 ? i : 5;
        }
    };



    return (
        <div className="App">
            {!!data && <TitleBar data={data} headerVisible={headerVisible}/>}
            {!!data && <div className={'container-wrapper'} ref={_containerHeight}>
                <Switch>
                    <Route exact path="/"><Main render={renderBackground}/></Route>
                    <Route path="/legal"><Legal render={renderBackground}/></Route>
                    <Route path="/other"><Projects render={renderBackground}/></Route>
                    <Route path="/gallery"><Gallery render={renderBackground}/></Route>
                </Switch>
                {!!data && !hiddenFooter && <FooterBar extraClasses={'sm-show'}/>}
            </div>}
            <div className={'fullSize background fixed sm-hidden'}/>
            <div className={'animation-base animation1 sm-hidden'}/>
            <div className={'animation-base animation2 sm-hidden'}/>
            {[...Array(backgroundCount + 1)].map((e, i) => (
                <div key={i} name={(i + 1)} className={'sm-hidden fullSize ab' + backgroundRenderNumber(i + 1)}/>
            ))}
            {!!data && !hiddenFooter && <FooterBar extraClasses={'sm-hidden'}/>}
        </div>
    );
}

export default App;