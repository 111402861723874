import React, {useEffect, useState} from 'react';
import {animateScroll as scroll} from "react-scroll";
import {Element} from "react-scroll/modules";
import ReactMarkdown from "react-markdown";

const Legal = ({render}) => {
    const [legal, setLegal] = useState("");

    useEffect(() => {
        if(legal === ""){
            scroll.scrollTo('legal');
            fetch('/config/legal.md').then( res => {
                const reader = res.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                // When no more data needs to be consumed, close the stream
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            })
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => blob.text())
            .then(text => { setLegal(text); render();})
            .catch(err => console.error(err));
        }
    }, [legal, render]);

    return legal !== "" && <Element name={'legal'} className={'container-height'} style={{padding: '1em'}}>
            <ReactMarkdown source={legal} />
        </Element>
};

export default Legal;