import React from 'react';
import logoC from "../../res/vreec-logo-19-c.png";
import logoA from "../../res/vreec-logo-19-a.png";
import {Link as RouterLink} from "react-router-dom";
import {Link} from "react-scroll";

function FooterBar({extraClasses}) {
    return  <div className={'footer ' + extraClasses}>
        <div className={'footer-small-logo-wrapper'}>
            <img src={logoC} alt={'Tu Vreecliber Logo'} className={'logo'}/>
            <img src={logoA} alt={'Vreeclimber Text Logo'} className={'logo'}/>
        </div>
        <div className={'footer-container'}>
            <ul className={'link-list'}>
                <li><RouterLink to="/legal">Legal</RouterLink></li>
                <li><Link to={'contact'} smooth={true} duration={1000}>Contact</Link></li>
                <li><RouterLink to="/gallery">Gallery</RouterLink></li>
                <li><RouterLink to="/other">Other Projects </RouterLink></li>
            </ul>
            <ul className={'info-list'}>
                <li>website by florian hotarek</li>
                <li>powered by react</li>
                <li>icons by nounproject & fontawesome</li>
            </ul>
        </div>
    </div>
}

export default FooterBar