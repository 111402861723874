import React from 'react';
import vid1 from "../../res/IntroVideo.mp4";

function VideoContainer () {
    return <div className={'video-container about'}>
        <video autoPlay loop muted>
            <source src={vid1} type="video/mp4"/>
            Your browser does not support the video tag.
        </video>
    </div>
}

export default VideoContainer;