import React from 'react'
import ReactMarkdown from "react-markdown";
import {Element} from "react-scroll";
import ReactPlayer from "react-player"

function AboutContainer({data}) {
    return <Element name={'about'} className={'container about'}>
        <div className={'header-line'}>
            <h2>{data.header}</h2>
        </div>
        <ReactMarkdown source={data.text} className={'text'}/>
        {!!data.url && <ReactPlayer
            url={data.url}
        />
        }
    </Element>
}

export default AboutContainer;