import React from "react";
import ReactMarkdown from "react-markdown";

const SponsorElement = ({number, sponsor}) => {
    if(number % 2 !== 0){
        return(
            <div key={number} className={'sponsor-container border-radius-left'}>
                <div className={'flex-wrapper fix-padding-left'}>
                    <img className={'img first'} src={sponsor.icon} alt={sponsor.name}/>
                    <div className={'description'}>
                        <h2>{sponsor.name}</h2>
                        <ReactMarkdown source={sponsor.description} />
                    </div>
                </div>
            </div>
        )
    }else{
        return (
            <div key={number} className={'sponsor-container border-radius-right'}>
                <div className={'flex-wrapper fix-padding-right'}>
                    <div className={'description'}>
                        <h2>{sponsor.name}</h2>
                        <ReactMarkdown source={sponsor.description} />
                    </div>
                    <img className={'img first'} src={sponsor.icon} alt={sponsor.name}/>
                </div>
            </div>
        )
    }
};

export default SponsorElement;