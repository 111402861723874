import React from 'react'
import {Map, TileLayer, Marker, Popup} from 'react-leaflet'
//import 'leaflet/dist/leaflet.css';

export default function GeoLocation() {
    const state = {
        lat: 48.196441,
        lng: 16.368668,
        zoom: 19,
    };

    const position = [state.lat, state.lng];
    return <Map center={position} zoom={state.zoom} style={{height:'100%', width: '100%'}}>
        <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
            <Popup>
                <a href="https://www.google.at/maps/dir//Gu%C3%9Fhausstra%C3%9Fe+30,+1040+Wien/@48.1955702,16.3693308,17z/data=!4m17!1m7!3m6!1s0x476d078230b90333:0xa81fd8396dea8f69!2sGu%C3%9Fhausstra%C3%9Fe+30,+1040+Wien!3b1!8m2!3d48.1964064!4d16.3686569!4m8!1m0!1m5!1m1!1s0x476d078230b90333:0xa81fd8396dea8f69!2m2!1d16.368657!2d48.1964064!3e1" target={'_blank'}>
                    Gußhausstraße 30,<br/>
                    1040 Wien,<br/>
                    Austria
                </a>
            </Popup>
        </Marker>
    </Map>

}