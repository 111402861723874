import React from "react";

const ContributorElement = ({number, contributor}) => {
    if(number % 2 !== 0){
        return(
            <div key={number} className={'cont-container'}>
                <div className={'cont-icon sm-hidden ' + ( !!contributor.size ? contributor.size : 'small' )}><img src={contributor.icon} alt={'icon'}/></div>
                <div className={'cont-wrapper'}>
                    <div className={'cont-info'}>
                        <div className={'cont-name ' + ( !!contributor.size ? contributor.size : 'small' )}>{contributor.prefixTitle} {contributor.name} {contributor.suffixTitle}</div>
                        <div className={'cont-short ' + ( !!contributor.size ? contributor.size : 'small' )}>{contributor.short}</div>
                        <div className={'cont-description ' + ( !!contributor.size ? contributor.size : 'small' )}>{contributor.description}</div>
                    </div>
                </div>
            </div>
        )
    }else{
        return (
            <div key={number} className={'cont-container reverse'}>
                <div className={'cont-wrapper'}>
                    <div className={'cont-info'}>
                        <div className={'cont-name ' + ( !!contributor.size ? contributor.size : 'small' )}>{contributor.prefixTitle} {contributor.name} {contributor.suffixTitle}</div>
                        <div className={'cont-short ' + ( !!contributor.size ? contributor.size : 'small' )}>{contributor.short}</div>
                        <div className={'cont-description ' + ( !!contributor.size ? contributor.size : 'small' )}>{contributor.description}</div>
                    </div>
                </div>
                <div className={'cont-icon sm-hidden ' + ( !!contributor.size ? contributor.size : 'small' )}><img src={contributor.icon} alt={'icon'}/></div>
            </div>
        )
    }
};

export default ContributorElement;