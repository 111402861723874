import React from 'react'
import ReactMarkdown from "react-markdown";
import ContributorElement from "../ContributorElement";
import ContributorSmallElement from "../ContributorSmallElement";
import {Element} from "react-scroll";

function ContributorContainer({data}) {
    return <Element name={'contributors'} className={'container contributors'}>
        <div className={'header-line'}>
            <h2>{data.header}</h2>
        </div>
        <ReactMarkdown source={data.text} className={'text'}/>

        {!!data.contributors && data.contributors.filter(contributor => contributor.size !== 'small').map((cont, i) => (
            <ContributorElement key={i} number={i} contributor={cont}/>
        ))}
        <div className={'small-contributors'}>
            {!!data.contributors && data.contributors.filter(contributor => contributor.size !== 'big' && contributor.size !== 'medium')
            .map((cont, i) => (
                <ContributorSmallElement key={i} number={i} contributor={cont}/>
            ))}
        </div>

    </Element>
}

export default ContributorContainer