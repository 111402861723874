import React from 'react'
import ReactMarkdown from "react-markdown";
import SponsorElement from "../SponsorElement";
import {Element} from "react-scroll";

function SponsorContainer({data}) {
    return <Element name={'sponsors'} className={'container sponsors'}>
        <div className={'header-line'}>
            <h2>{data.header}</h2>
        </div>
        <ReactMarkdown source={data.text} className={'text'}/>

        {!!data.sponsors && data.sponsors.map((element, i) => (
            <SponsorElement key={i} number={i} sponsor={element}/>
        ))}
    </Element>
}

export default SponsorContainer