import React from 'react';
import ReactMarkdown from "react-markdown";

const ProjectElement = ({project}) => {
    return <div className={'project-element'}>
        <a href={project.url} target="_blank" rel="noopener noreferrer" className={'image-wrapper'}>
            <img src={project.image} alt={project.alt} style={{backgroundColor: project.backgroundColor}}/>
        </a>
        <div className={'project-name'}>
            {project.name}
        </div>
        <ReactMarkdown className={'project-description'}>
            {project.description}
        </ReactMarkdown>
        <div className={'project-link'}>
            <a href={project.url} target="_blank" rel="noopener noreferrer">{project.url}</a>
        </div>
    </div>
};

export default ProjectElement;