import React, {useState} from 'react'
import linkIcon from "../../res/link-icon.svg";
import ReactMarkdown from "react-markdown";
import ImageGallery from "react-image-gallery";
import {Link as RouterLink} from "react-router-dom";
import {Element} from "react-scroll";

function EventContainer({data}) {
    const [eventDescriptionOpen, setEventDescriptionOpen] = useState(null);

    const setEvent = index => () => {
        setEventDescriptionOpen(eventDescriptionOpen === index ? null : index);
    };

    return <Element name={'events'} className={'container no-bottom wrap-container justify-start events'}>
        <div className={'header-line'}>
            <h2>{data.header}</h2>
        </div>
        {!!data.events && data.events.map((event, i) => {
            return <div key={i} className={'event-container'}>
                <h2> {event.name} </h2>
                <div className={'sub-header'}>{event.start} - {event.end}</div>
                { !!event.url && <a href={event.url} className={'event-url'}><img src={linkIcon} className={'link-icon'} alt={'link'}/> <span>{event.url}</span></a>}
                <div className={'event-short ' + (eventDescriptionOpen === i || !event.gallery || event.gallery.length <= 0 ? 'auto' : '') + (!event.description || event.description.length <= 0 ? ' missing': '' ) } onClick={setEvent(i)}>
                    <div className={'event-short-text-wrapper'}>
                        {!!event.description && <ReactMarkdown source={event.description}/>}
                    </div>
                    {/* (!event.description || event.description.length*/}
                    { !!event.description && eventDescriptionOpen !== i && <div className={'read-more'}>Click to read More</div> }
                </div>
                <div className={'size-gallery'}>
                    {!!event.gallery && event.gallery.length > 0 &&
                    <ImageGallery showThumbnails={false} showBullets={true} showPlayButton={false} items={event.gallery}/>}
                </div>
            </div>
        })}
        <div className={'black-container'}>
            <div className={'line'}>
                <a href={data.pressDocumentPath}>{data.pressReleaseText}</a>
            </div>
            <div className={'line'}>
                <RouterLink to="/gallery">{data.galleryLinkText}</RouterLink>
            </div>

        </div>
    </Element>
}

export default EventContainer