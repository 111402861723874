import React, {useRef, useState, useEffect} from 'react';
import {FontAwesomeIcon as Icon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";

function Roller({data}) {
    const [p] = useState(0);
    const [i] = useState(null);
    const wallRef = useRef(null);
    const wallRef2 = useRef(null);
    let pos = useRef(p);
    let id = useRef(i);

    useEffect(() => {
        animation();
        return () => {
            clearInterval(id);
        }
    });

    const animation = () => {
        id = setInterval(frame, 40);

        function frame() {
            if (pos.current === wallRef.current.clientHeight) {
                pos.current = 0;
                clearInterval(id);
                animation();
            } else {
                pos.current++;
                wallRef.current.style.top = '-' + pos.current + 'px';
                wallRef2.current.style.top = '-' + pos.current + 'px';
            }
        }
    };

    const addScroll = () => {
        if (pos.current + 100 > wallRef.current.clientHeight) {
            pos.current = 0;
        } else {
            pos.current = pos.current + 100;
        }
    };

    return <div className={'roller'}>
        <div className={'roller-wall'} ref={wallRef}>
            {!!data && data.length > 0 && data.map((element, index) => <div key={index} className={'roller-element'}>
                <h2>{element.name}</h2>
                <img className={'img'} src={element.icon} alt={element.name}/>
                <div className={'description-text'}>{element.description}</div>
            </div>)}
        </div>
        <div className={'roller-wall'} ref={wallRef2}>
            {!!data && data.length > 0 && <div className={'roller-element'}>
                <h2>{data[0].name}</h2>
                <img className={'img'} src={data[0].icon} alt={data[0].name}/>
                <div className={'description-text'}>{data[0].description}</div>
            </div>}
        </div>
        <button onClick={addScroll} className={'scroll-button'}><Icon icon={faChevronDown}/></button>
        <div className={'shadow-element'} />
    </div>
}

export default Roller;