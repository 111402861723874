import React, {useEffect, useState} from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import { VideoContainer, AboutContainer, TechnologyContainer, SetupContainer, SponsorContainer, EventContainer, ContributorContainer, ContactContainer } from "../components";


const Main = ({render}) => {

    const [data, setData] = useState({});
    const [aboutData, setAboutData] = useState({});
    const [techData, setTechData] = useState({});
    const [setupData, setSetupData] = useState({});
    const [sponsorsData, setSponsorsData] = useState({});
    const [eventsData, setEventsData] = useState({});
    const [contactData, setContactData] = useState({});
    const [mailtoData, setMailtoData] = useState({});
    const [contributorData, setContributorData] = useState({});

    useEffect(() => {
        if(Object.keys(data).length <= 0){
            fetchData('/config/config.json').then((res) => {
                setData(res);
                return fetchData('/config/about.json')
            }).then((res) => {
                setAboutData(res);
                return fetchData('/config/technologies.json');
            }).then((res) => {
                setTechData(res);
                return fetchData('/config/setup.json');
            }).then((res) => {
                setSetupData(res);
                return fetchData('/config/sponsors.json');
            }).then((res) => {
                setSponsorsData(res);
                return fetchData('/config/events.json')
            }).then((res) => {
                setEventsData(res);
                return fetchData('/config/contact.json');
            }).then((res) => {
                setContactData(res);
                return fetchData('/config/mailto.json');
            }).then((res) => {
                setMailtoData(res);
                return fetchData('/config/contributor.json');
            }).then((res) => {
                setContributorData(res);
                render();
            });
        }
    }, [data, render]); // mount


    const fetchData = async filePath => {
        try {
            const res = await fetch(filePath);
            return await res.json();
        } catch (err) {
            return err;
        }
    };

    return (
        <div className={'container-height'}>
            <VideoContainer/>
            {data.about && <AboutContainer data={aboutData} /> }
            {data.technologies && <TechnologyContainer data={techData} /> }
            {data.setup && <SetupContainer data={setupData} mailTo={mailtoData} /> }
            {data.sponsors && <SponsorContainer data={sponsorsData} /> }
            {data.events && <EventContainer data={eventsData} /> }
            {data.contributor && <ContributorContainer data={contributorData} /> }
            {data.contact && <ContactContainer data={contactData} mailTo={mailtoData} /> }
        </div>
    )
};

export default Main;