import ReactTooltip from "react-tooltip";
import React from "react";

export default function ContributorSmallElement ({number, contributor }) {
    return <div className={'small-contributor'} data-tip={contributor} data-for={'cont' + number} >
        {contributor.name}
        <ReactTooltip id={'cont' + number} place="top" type="dark" effect="float">
            <div>{contributor.name}</div>
            <div>{contributor.short}</div>
            <div>{contributor.description}</div>
        </ReactTooltip>
    </div>
}