import React from 'react'
import {Element} from "react-scroll";
import ReactMarkdown from "react-markdown";
import {MailToElement, SetupElement} from "../index";
import vid2 from "../../res/SetupVideo.mp4";
import {Link as RouterLink} from "react-router-dom";

function SetupContainer({data, mailTo}) {
    return <div className={'setup'}>
        <Element name={'setup'} className={'container'}>
            <div className={'header-line'}>
                <h2>{data.header}</h2>
            </div>
            {!!data.text && <ReactMarkdown source={data.text} className={'text'}/>}
            {!data.text && <div className={'placeholder'}/>}
            {!!data.bulletPoints && data.bulletPoints.map((element, i) => (
                <SetupElement key={i} element={element} i={i}/>
            ))}
        </Element>

        <div className={'video-container'}>
            <video autoPlay loop muted>
                <source src={vid2} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
        </div>

        <div className={'black-container link-container'}>
            <div className={'line'}>
                <span>Want to see more ? </span>
                <RouterLink to="/gallery">Go to the Gallery!</RouterLink>
            </div>
            <div className={'line'}>
                <span>{data.secondText} </span>
                <MailToElement mailtoData={mailTo} text={'Contact us!'}/>
            </div>
        </div>
    </div>
}

export default SetupContainer;