import React from 'react';
import setupBackgroundBlue from "../../res/setup_background_blue.svg";
import setupBackgroundGreen from "../../res/setup_background_green.svg";
import setupBackgroundRed from "../../res/setup_background_red.svg";
import setupBackgroundViolet from "../../res/setup_background_violet.svg";
import setupBackgroundYellow from "../../res/setup_background_yellow.svg";
import setupBackground from "../../res/setup_background.svg";


const ImgElement = ({rotation, color}) => {
    switch (color) {
        case 'blue':
            return <img src={setupBackgroundBlue} className={'setup-icon-background'} style={{transform: 'rotate(' + rotation + 'deg)'}} alt="climbing hold"/>;
        case 'green':
            return <img src={setupBackgroundGreen} className={'setup-icon-background'} style={{transform: 'rotate(' + rotation + 'deg)'}} alt="climbing hold"/>;
        case 'red':
            return <img src={setupBackgroundRed} className={'setup-icon-background'} style={{transform: 'rotate(' + rotation + 'deg)'}} alt="climbing hold"/>;
        case 'violet':
            return <img src={setupBackgroundViolet} className={'setup-icon-background'} style={{transform: 'rotate(' + rotation + 'deg)'}} alt="climbing hold"/>;
        case 'yellow':
            return <img src={setupBackgroundYellow} className={'setup-icon-background'} style={{transform: 'rotate(' + rotation + 'deg)'}} alt="climbing hold"/>;
        default :
            return <img src={setupBackground} className={'setup-icon-background'} style={{transform: 'rotate(' + rotation + 'deg)'}} alt="climbing hold"/>;
    }
};

export default ImgElement;