import React from 'react'
import ReactMarkdown from "react-markdown";
import {Roller} from "../index";
import {FontAwesomeIcon as Icon} from "@fortawesome/react-fontawesome";
import {faEquals} from "@fortawesome/free-solid-svg-icons";
import {Element} from "react-scroll";

function TechnologyContainer({data}) {
    return <Element name={'technologies'} className={'container no-padding-bottom technologies'}>
        <div className={'header-line'}>
            <h2>{data.header}</h2>
        </div>
        <ReactMarkdown source={data.text} className={'text'}/>
        <Roller data={data.technologies} />
        <div className={'sum-container no-padding-bottom'}>
            <div className={'vreeclimber-description'}>&sum; of all technologies <Icon className={'equal-icon'} icon={faEquals}/>
            </div>
            <div className={'vreeclimber'}>VREECLIMBER</div>
        </div>
    </Element>
}

export default TechnologyContainer