import React from 'react';
import ReactMarkdown from "react-markdown";
import {ImgElement} from "../index";

const SetupElement = ({element, i}) => {
    if (!!element.icon) {
        if (i % 2 !== 0) {
            return <div key={i} className={'setup-wrapper'}>
                <ReactMarkdown source={element.text} className={'text text-margin'}/>
                <div className={'setup-icon-container'}>
                    <ImgElement color={element.color} rotation={element.rotation}/>
                    <img src={element.icon} className={'setup-icon'} alt={i}/>
                </div>
            </div>
        } else {
            return <div key={i} className={'setup-wrapper'}>
                <div className={'setup-icon-container'}>
                    <ImgElement color={element.color} rotation={element.rotation}/>
                    <img src={element.icon} className={'setup-icon'} alt={i}/>
                </div>
                <ReactMarkdown source={element.text} className={'text text-margin'}/>
            </div>
        }
    } else {
        return <div key={i} className={'setup-wrapper'}>
            <ReactMarkdown source={element.text} className={'text'}/>
        </div>
    }

};

export default SetupElement;